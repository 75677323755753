<style lang="scss" scoped>
.b {
	margin: 0.4rem 0.4rem 0.4rem;
	font-size: 0.24rem;
}
.form_box {
	border-radius: 0.5rem;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
}
.form_box_2 {
	border-radius: 0.2rem;
}
.tabs,
.tabs_2 {
	border: 1px solid $b_color_main;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	height: 1rem;
	text-align: center;
	display: flex;
	align-items: center;
	overflow: hidden;
	color: $b_color_main;
	.tab {
		flex: 1;
		flex-shrink: 0;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.tab.rows {
		flex-direction: column;
		.text {
			font-size: 0.2rem;
		}
	}
	.active {
		background-color: $b_color_main;
		color: #fff;
	}
}
.tabs_2 {
	margin: 0 0.44rem;
	height: 0.8rem;
	border-radius: 0.4rem;
}

::v-deep .van-field {
	padding: 16px;
}
::v-deep .van-field::after {
	border-bottom: 1px solid $b_border_color;
}
.btns {
	padding: 16px 16px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.btn_inner {
		padding: 0 0.3rem;
		position: relative;
		top: 1px;
		.icon {
			margin-right: 4px;
		}
	}
}

.active_title {
	padding: 0.2rem 0;
}
.active_title_text {
	color: $font_color_main;
	font-weight: bold;
}
.active_title_2 {
	padding: 0.24rem 0.2rem;
}
.list {
	margin-bottom: 0.4rem;
	padding: 0 0.16rem;
	.row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.2rem 0;
		border-bottom: 1px solid $line_color;
		.icon.delet {
			color: $font_color_sec;
			font-size: 14px;
			margin-right: 10px;
		}
		.name {
			margin-right: 8px;
		}
		.btn {
			color: $b_color_main;
			.icon {
				margin-right: 4px;
			}
		}
	}
}

.van-cell:last-child::after,
.van-cell--borderless::after {
	display: initial;
}
::v-deep .edit_icon {
	color: $b_color_main;
	margin-right: 8px;
}
.flex_between {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 0.2rem;
}
.new_main_title {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 0.28rem;
	font-weight: 600;
}
.line_title {
	width: 0.08rem;
	height: 0.32rem;
	background: $b_color_main;
	margin-right: 0.2rem;
}
.ml_0 {
	margin: 0.2rem 0 0.2rem;
}
</style>

<template>
	<div class="b">
		<!-- 被保人列表 -->
		<div v-show="assuredList.length" class="list">
			<div class="flex_between">
				<div class="new_main_title">
					<div class="line_title"></div>
					<span>被保险人信息</span>
				</div>
				<div>目前添加{{ assuredList.length }}人</div>
			</div>
			<div class="row" v-for="(item, index) in assuredList" :key="index">
				<div>
					<span class="icon iconfont ybx_delete delet" @click="delet(index)"></span>
					<span class="name" v-text="item.userName"></span>
					<span class="cardnum" v-text="item.cardNum"></span>
				</div>
				<span class="btn" @click="edit(index, item)">
					<span class="icon iconfont ybx_edit"></span>
					<span>修改</span>
				</span>
			</div>
		</div>

		<!-- 添加被保人 -->
		<div class="form_box" :class="{ form_box_2: assuredList.length }" v-if="proId">
			<div v-if="!assuredList.length" class="tabs">
				<span class="tab" :class="{ active: tabActiveIndex === 0 }" @click="checkTab(0)">
					<span>中国大陆居民</span>
				</span>
				<span v-if="proId != 27555" class="tab rows" :class="{ active: tabActiveIndex === 1 }" @click="checkTab(1)">
					<div>Non Chinese Resident</div>
					<div class="text">(持护照及港澳台证件)</div>
				</span>
			</div>

			<template v-else>
				<div class="new_main_title active_title_2">
					<span v-if="isEdit" class="icon iconfont ybx_edit edit_icon"></span>
					<div v-else class="line_title"></div>
					<span v-if="isEdit" class="active_title_text">修改成员信息</span>
					<span v-else class="active_title_text">其它成员信息</span>
				</div>
				<div class="tabs_2">
					<span class="tab" :class="{ active: tabActiveIndex === 0 }" @click="checkTab(0)">
						<span>中国大陆居民</span>
					</span>
					<span v-if="proId != 27555" class="tab rows" :class="{ active: tabActiveIndex === 1 }" @click="checkTab(1)">
						<div>Non Chinese Resident</div>
						<div class="text">(持护照及港澳台证件)</div>
					</span>
				</div>
			</template>

			<!-- 身份证投保 -->
			<van-form v-show="tabActiveIndex === 0" ref="form">
				<van-cell-group :border="false">
					<van-field label="姓名" v-model="userInfo.userName" name="userName" :formatter="nameFormatter" :rules="rules.userName" placeholder="请输入您的姓名" />
					<van-field label="身份证号" v-model="userInfo.cardNum" name="caerNum" :formatter="idCardNoFormatter" @input="cardNumChange" :rules="rules.cardNum" placeholder="请输入您的身份证号码" />
					<van-field label="手机号" v-model="userInfo.phone" name="phone" :formatter="mobileFormatter" :rules="rules.phone" placeholder="请输入您的手机号码" />
				</van-cell-group>
			</van-form>

			<!-- 非身份证 -->
			<van-form v-show="tabActiveIndex === 1" ref="notIdCardForm">
				<van-cell-group :border="false">
					<van-field label="姓名" v-model="userInfo.userName" name="userName" :formatter="nameFormatter" :rules="rules.userName" placeholder="Full Name/姓名" />
					<van-field label="证件号" v-model="userInfo.cardNum" name="caerNum" :formatter="idCardNoFormatter" @input="cardNumChange" :rules="rules.notCardNum" placeholder="Passport Number/港澳台证件号" />
					<van-field label="出生日期" :value="userInfo.birthday" is-link readonly clickable name="picker" :rules="rules.birthday" placeholder="Date of Birth/出生日期" @click="birthdayPop = true" />
					<van-field label="性别" :value="userInfo.sex" is-link readonly clickable name="picker" :rules="rules.sex" placeholder="男M/女F" @click="sexPop = true" />
					<van-field label="手机号" v-model="userInfo.phone" name="phone" :formatter="mobileFormatter" :rules="rules.phone" placeholder="选填optional" />
				</van-cell-group>
			</van-form>

			<!-- 按钮 -->
			<div class="btns">
				<template v-if="!isEdit">
					<van-button round size="small" @click="clearForm">
						<span class="btn_inner">清除</span>
					</van-button>
					<van-button round size="small" type="primary" @click="moreAssured">
						<span class="btn_inner">
							<van-icon class="icon" name="plus"></van-icon>
							<span>更多被保险人</span>
						</span>
					</van-button>
				</template>
				<template v-else>
					<van-button round size="small" @click="editCancel">
						<span class="btn_inner">取消修改</span>
					</van-button>
					<van-button round size="small" type="primary" @click="editSave">
						<span class="btn_inner">
							<span>保存修改</span>
						</span>
					</van-button>
				</template>
			</div>
		</div>

		<!-- 弹出层 -->
		<!-- 出生日期 -->
		<van-popup v-model="birthdayPop" position="bottom" :style="{ height: '30vh' }">
			<van-datetime-picker v-model="birthday" type="date" title="出生日期/Birthday" confirm-button-text="确定/OK" cancel-button-text="取消/Cancel" @cancel="birthdayPop = false" @confirm="birthdayCheck" visible-item-count="3" :min-date="birthdayMinDate" />
		</van-popup>
		<!-- 性别 -->
		<van-popup v-model="sexPop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="性别/Gender" confirm-button-text="确定/OK" cancel-button-text="取消/Cancel" show-toolbar value-key="label" :visible-item-count="3" :columns="sexList" @confirm="sexCheck" @cancel="sexPop = false" />
		</van-popup>
	</div>
</template>

<script>
import { Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, Form, Button, Icon, Toast } from 'vant';
import { sexs } from '@/config/fixedParams';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';

export default {
	name: 'assured', // 被保人信息
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[Form.name]: Form,
		[Button.name]: Button,
		[Icon.name]: Icon,
	},
	props: {
		// 产品ID 嗨玩九州境内游被保人证件类型只能是身份证
		proId: {
			type: Number | String,
			required: false,
			default: '',
		},
	},

	data() {
		return {
			assuredList: [],

			// tab切换高亮
			tabActiveIndex: 0,

			// 表单信息
			userInfo: {
				userName: '',
				cardType: '01', // 证件类型 身份证01，护照02
				cardNum: '',
				birthday: '',
				sex: '',
				phone: '',
			},

			rules: {
				userName: [
					{ required: true, message: '' },
					{ pattern: regular.name1, message: '只能输入中文或英文以及·' },
					{ pattern: regular.name2, message: '至少2个中文或4个英文字母' },
					{ validator: this.nameCheck, message: '·不能出现在首尾' },
				],
				cardNum: [
					{ required: true, message: '' },
					{ validator: this.cardNumCheck, message: '证件号码有误' },
					{ validator: this.repeatCheck, message: '证件号码已经存在' },
				],
				notCardNum: [
					{ required: true, message: '' },
					{ validator: this.notCardNumCheck, message: '证件号码有误' },
					{ validator: this.repeatCheck, message: '证件号码已经存在' },
				],
				phone: [{ validator: this.phoneCheck, message: '请填写正确的手机号码', trigger: 'onBlur' }],
				birthday: [{ required: true }],
				sex: [{ required: true }],
			},

			birthdayPop: false,
			sexPop: false,
			birthdayMinDate: new Date('1900/01/01'),
			birthday: new Date('1990/01/01'),
			sexList: [],

			isEdit: false,
			editIndex: -1,
		};
	},
	created() {
		this.initSexList();
	},
	methods: {
		// 初始化性别列表
		initSexList() {
			this.sexList = sexs.map(item => {
				return {
					label: item.label === '男' ? '男/male' : '女/female',
					value: item.value === '男' ? '男/male' : '女/female',
				};
			});
		},

		// 投保人姓名格式化
		nameFormatter(v) {
			return v.toLocaleUpperCase().replace(/ /g, '');
		},

		// 身份证号码格式化
		idCardNoFormatter(v) {
			return String(v).toLocaleUpperCase().replace(/ /g, '');
		},

		// 手机号码格式化
		mobileFormatter(v) {
			return String(v).replace(/ /g, '');
		},

		// rule-投保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},

		// rule-身份证号码校验方法
		cardNumCheck(val) {
			return Mtils.validation.isIdCard(val);
		},

		// rule-非身份证号码校验
		notCardNumCheck(val) {
			return regular.passport.test(val) && !regular.repeatReg7.test(val) && !regular.sequentialReg7.test(val);
		},

		// rule-正价号码重复校验
		repeatCheck(val) {
			let numList = this.assuredList.filter((item, index) => index !== this.editIndex).map(item => item.cardNum);
			return !numList.includes(val);
		},

		// rule-手机号码校验
		phoneCheck(val) {
			if (!val) {
				return true;
			}
			return regular.phone.test(val);
		},

		// 证件号码输入-计算出生日期与性别
		cardNumChange(val) {
			if (Mtils.validation.isIdCard(val)) {
				let info = this.$base.getInfoFromIdNumber(val);
				this.userInfo.birthday = info.birthday;
				this.userInfo.sex = info.sex;
			}
		},

		birthdayCheck(val) {
			this.userInfo.birthday = this.$base.dateFormater(val);
			this.birthdayPop = false;
		},

		sexCheck(val) {
			this.userInfo.sex = val.label;
			this.sexPop = false;
		},

		// 切换tab
		checkTab(val) {
			if (this.isEdit) {
				return;
			}
			this.tabActiveIndex = val;
			this.userInfo.cardType = val === 0 ? '01' : '02';
			this.clearForm();
		},

		// 清除表单内容
		clearForm() {
			this.userInfo = {
				userName: '',
				cardType: this.tabActiveIndex === 0 ? '01' : '02', // 证件类型 身份证01，护照02
				cardNum: '',
				birthday: '',
				sex: '',
				phone: '',
			};
			this.$refs.form.resetValidation();
			this.$refs.notIdCardForm.resetValidation();
		},

		// 更多被保险人
		moreAssured() {
			return new Promise(resolve => {
				if (!this.tabActiveIndex) {
					this.validateForm('form').then(info => {
						this.assuredList.push(info);
						this.clearForm();
						resolve();
					});
				} else {
					this.validateForm('notIdCardForm').then(info => {
						this.assuredList.push(info);
						this.clearForm();
						resolve();
					});
				}
			});
		},

		// 校验表单信息
		validateForm(domRef) {
			return new Promise(resolve => {
				this.$refs[domRef].validate().then(() => {
					resolve(this.userInfo);
				});
			});
		},

		// 删除被保人
		delet(index) {
			this.assuredList.splice(index, 1);
		},

		// 修改被保人
		edit(index, info) {
			this.editIndex = index;
			this.tabActiveIndex = info.cardType === '01' ? 0 : 1;
			Object.assign(this.userInfo, info);
			this.isEdit = true;
		},

		// 取消修改
		editCancel() {
			this.isEdit = false;
			this.editIndex = -1;
			this.clearForm();
		},

		// 保存修改
		editSave() {
			if (!this.tabActiveIndex) {
				this.validateForm('form').then(info => {
					this.assuredList.splice(this.editIndex, 1, info);
					this.clearForm();
					this.editIndex = -1;
					this.isEdit = false;
				});
			} else {
				this.validateForm('notIdCardForm').then(info => {
					this.assuredList.splice(this.editIndex, 1, info);
					this.clearForm();
					this.editIndex = -1;
					this.isEdit = false;
				});
			}
		},

		// 性别格式化
		sexFormatter(text) {
			if (text === '男' || text === 'male' || text === '男/male') {
				return '男';
			}

			if (text === '女' || text === 'female' || text === '女/female') {
				return '女';
			}
			return text;
		},

		// 获取数据
		getResult() {
			return new Promise(resolve => {
				// 如果表单中有姓名或证件号码，就校验表单，并且把表单中的信息一并收集，否则只收集列表中的被保人
				if (this.userInfo.userName || this.userInfo.cardNum) {
					this.moreAssured().then(() => {
						if (this.assuredList.length) {
							let list = this.assuredList.map(item => {
								item.sex = this.sexFormatter(item.sex);
								return item;
							});
							resolve(list);
						} else {
							Toast.fail('请添加被保险人');
						}
					});
				} else {
					if (this.assuredList.length) {
						let list = this.assuredList.map(item => {
							item.sex = this.sexFormatter(item.sex);
							return item;
						});
						resolve(list);
					} else {
						Toast.fail('请添加被保险人');
					}
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.body {
	position: relative;
	min-height: 100vh;
	padding-bottom: 2rem;
	box-sizing: border-box;
}
.describe {
	font-size: 0.24rem;
	line-height: 0.5rem;
	padding: 0.3rem;
}
.btns {
	padding: 0.3rem;
}

::v-deep .banner {
	margin: 0;
	img {
		width: 100%;
		height: 4.1rem;
	}
}

.hide_info {
	display: none;
}
.success_sign {
	margin: 0.3rem;
	font-size: 0.28rem;
	font-weight: normal;
}

footer {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	box-sizing: border-box;
	padding: 1rem 0.3rem 0.3rem;
	font-size: 0.2rem;
	text-align: center;
	z-index: -1;
}
.success_content {
	overflow: hidden;
}
.weChat_warning {
	font-size: 0.4rem;
	text-align: center;
}

.btn_box {
	padding: 0 0.4rem;
}
.submit_btn {
	border-radius: 8px;
}
.describ {
	font-size: 0.2rem;
	margin-top: 0.44rem;
	margin: 0.44rem 0.88rem 0.08rem;
	color: $b_font_color_info;
	text-indent: 2em;
}
.detail_protect {
	font-size: 0.24rem;
	margin-left: 0.4rem;
}
.mb_4 {
	margin-bottom: 0.4rem;
}
.color_main {
	color: $color_main;
}
.btn_more {
	width: 90%;
	margin-top: 0.4rem;
}
.code_box {
	border-radius: 0.5rem;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
	margin: 0.4rem;
	overflow: hidden;
}
.mv_2 {
	margin-bottom: 0.2rem;
	margin-top: 0.2rem;
}
::v-deep .van-tab--active {
	background-color: #2594ef;
	color: $background_color;
}
::v-deep .van-tabs__line {
	height: 0;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 1rem;
}
.order_btn {
	border-radius: 8px;
}
</style>

<template>
	<div class="index_ins_user_pay">
		<div class="weChat_warning" v-if="weChatWarningShow">
			<h4>请在微信或支付宝中打开</h4>
		</div>
		<div v-else class="body">
			<template v-if="!showSuccess">
				<banner name="banner" :url="banner"></banner>

				<!-- 支付编码 -->
				<template v-if="qrCodeInfo.automaticPay === true && proSimpleInfo.id">
					<div class="code_box">
						<van-tabs v-model="activeName" type="line" line-width="30%" color="#2594EF" @change="cardTypeTabChange">
							<van-tab title="身份证" name="idCard">
								<assured ref="assuredIdCard" :onlyShowForm="true" :onlyUseIdcardForm="true" :isShowInsuranceCode="true" :proId="proSimpleInfo.id"></assured>
							</van-tab>
							<van-tab v-if="proSimpleInfo.id != 27555" title="passport" name="passport">
								<add-passport-user ref="assuredPassport" :onlyShowForm="true" :isShowBorder="true" :isShowInsuranceCode="true"></add-passport-user>
							</van-tab>
						</van-tabs>
					</div>
					<!-- 详细说明 -->
					<div class="detail_protect mv_2">详细保障，请阅读<span class="color_main" @click="handleGo(1)">《投保条款》</span>和<span class="color_main" @click="handleGo(2)">《保障详情》</span></div>
					<div class="btns">
						<van-button type="info" block @click="submit" class="order_btn">{{ $t('submit') }}</van-button>
					</div>
				</template>

				<!-- 保险信息登记页面（客户自主支付订单） -->
				<template v-else>
					<!-- <assured ref="assured" :isUserPay="true"></assured> -->
					<beautiful-assured :proId="proSimpleInfo.id" ref="beautifulAssured"></beautiful-assured>
					<!-- 详细说明 -->
					<div class="detail_protect mb_4">详细保障，请阅读<span class="color_main" @click="handleGo(1)">《投保条款》</span>和<span class="color_main" @click="handleGo(2)">《保障详情》</span></div>

					<!-- 提交按钮 -->
					<div class="btn_box">
						<van-button class="submit_btn" block type="info" @click="submit">提交订单</van-button>
					</div>

					<!-- 说明 -->
					<p class="describ">说明:此页面仅用于收集信息用于保险购买，具体是否承保以出具保单为准。我们将对您的信息进行严格的保密，并且只用于本次活动保险购买，请放心填写。</p>
				</template>
			</template>
			<success-page v-else class="success_content">
				<p v-text="showSuccessText"></p>
				<p v-if="qrCodeInfo.automaticPay === true" class="success_sign">请即刻联系工作人员核实，具体承保信息以实际保单展示为准</p>
				<p v-else class="success_sign">您的信息登记已完成，请联系现场工作人员核实，具体承保信息以实际保单展示为准</p>
				<!-- <van-button @click="showSuccess = false" class="btn_more" type="info">点击添加更多被保险人</van-button> -->
				<van-button @click="continueInsure" class="btn_more" type="info">点击添加更多被保险人</van-button>
			</success-page>

			<footer>
				<p>保险业务经营许可证：</p>
				<p>260405000000800 北京赛福哈博保险经纪有限公司</p>
				<p>合作及服务咨询：400-660-9195</p>
			</footer>

			<div class="hide_info">
				<insure-info name="投保信息" ref="insureInfo" @insureTimeChange="insureTimeChange" :fixedEnableChange="qrCodeInfo.updateEnabledDate" :fiexdEnableDate="qrCodeInfo.fixedEnabledDate" :product="proInfo" :planId="planId" :proId="qrCodeInfo.proId" :priceId="qrCodeInfo.priceId" :enabledDateStart="qrCodeInfo.enabledDateStart"></insure-info>
				<plan name="产品名称与计划" @planChange="planChange" :plans="planList" :proInfo="proSimpleInfo" :insureInfo="insureInfo" :information="information"></plan>
			</div>
		</div>
	</div>
</template>

<script>
import { Toast, Tab, Tabs, Button } from 'vant';
import banner from './components/banner.vue';
import assured from './components/assured.vue';
import beautifulAssured from './components/beautiful/assured.vue';
import plan from './components/plan.vue';
import insureInfo from './components/insureInfo.vue';
import addPassportUser from './addPassportUser.vue';
import successPage from '@/components/successPage.vue';
import { loginNoPwd, productDetail, getInsuerInfo, submitBill, getBillState, codePay } from '@/request/api';
import { http_getqrCodeInfo } from '@/request/fixedCodeInsure';
import { http_getServerTime } from '@/request/common';
import { insureUserType } from '@/config/fixedParams';
import signatureMixin from '@/components/signature.js';

export default {
	name: 'indexInsUserPay', // 会员支付模式（领取保险）
	components: {
		[Toast.name]: Toast,
		[Tab.name]: Tab,
		[Tabs.name]: Tabs,
		[Button.name]: Button,
		banner,
		assured,
		beautifulAssured,
		plan,
		addPassportUser,
		insureInfo,
		successPage,
	},
	mixins: [signatureMixin],
	data() {
		return {
			weChatWarningShow: false,
			userDesc: '',
			qrCodeId: '',

			proBanner: '', // 产品默认banner
			qrCodeInfo: {}, // 立牌信息
			proInfo: {}, // 产品信息
			proSimpleInfo: {}, // 产品信息，两个接口请求来的产品信息内容不同，切用到的参数不是交集的内容，所以只能设置两个
			proPlanList: [], // 产品默认计划
			insureInfo: {}, // 投保信息
			information: {}, //
			questionList: [], // 问答
			timeList: [],

			planId: NaN,

			// 下单多久之后为起保时间
			immediateExtendTime: 0,

			protectInfo: {}, // 保障期限信息

			activeName: 'idCard',

			showSuccess: false,
			showSuccessText: '信息登记已完成',

			lastRouteName: '', // 离开该页面时去的路由Name
		};
	},
	watch: {
		$route: {
			handler: function (route) {
				if (route.name !== 'fixedCodeInsUserPay') {
					this.lastRouteName = route.name;
				}
				if (route.query.hasOwnProperty('userInfo') && route.name === 'fixedCodeInsUserPay' && this.lastRouteName === 'addPassportUser') {
					this.$refs.assured.addPassportUser(JSON.parse(route.query.userInfo));
				}
			},
		},
	},
	computed: {
		banner() {
			return this.qrCodeInfo.banner ? this.qrCodeInfo.banner : this.proBanner;
		},
		planList() {
			if (Object.hasOwnProperty.call(this.qrCodeInfo, 'goods')) {
				let showPlans = this.qrCodeInfo.goods.split(',').map(id => Number(id));
				return this.proPlanList.filter(item => showPlans.includes(item.comboId));
			}
			return [];
		},
	},
	created() {
		this.init();
	},
	mounted() {
		// this.$base.setTitle('保险信息登记')
	},
	methods: {
		init() {
			if (!this.$base.isDev() || this.$base.getEnv()) {
				this.$base.setTitle('ㅤ');
				this.urlParams();

				let query = this.$route.query;
				if (Object.hasOwnProperty.call(query, 'openWelcome')) {
					// 从签名页来的，已经登录过了，这路不用再次登录
					this.getQrCodeInfo().then(() => {
						this.getGoods();
						this.getPlain();
					});
				} else {
					this.login().then(() => {
						this.getQrCodeInfo().then(() => {
							this.getGoods();
							this.getPlain();
						});
					});
				}
			} else {
				this.weChatWarningShow = true;
			}
		},

		// 获取地址中的参数
		urlParams() {
			if (window.location.search) {
				localStorage.setItem('homeUrl', window.location.href);
			} else {
				window.location.href = localStorage.getItem('homeUrl');
			}
			this.userDesc = this.$route.query.user;
			this.qrCodeId = this.$route.query.qrcode;

			// let paramsList = window.location.search.split(/=|&/);
			// this.userDesc = paramsList[1];
			// this.qrCodeId = paramsList[3];
		},

		// 登录
		login() {
			return new Promise(resolve => {
				loginNoPwd(this.userDesc).then(() => {
					resolve();
				});
			});
		},

		// 获取立牌信息
		getQrCodeInfo() {
			return new Promise(resolve => {
				http_getqrCodeInfo(this.qrCodeId).then(res => {
					khs.start(res.userId);
					this.$set(this, 'qrCodeInfo', res);
					this.$store.commit('set_productId', res.proId);
					if (res.automaticPay) {
						this.$base.setTitle('领取保险');
					} else {
						this.$base.setTitle('保障领取登记');
					}
					resolve(res);
				});
			});
		},

		// 获取商品信息
		getGoods() {
			productDetail(this.qrCodeInfo.proId).then(res => {
				this.proBanner = res.product.proPicUrlIndexBig;
				this.insureInfo = res.insurer;
				this.proSimpleInfo = res.product;
				this.information = res.information;
				this.questionList = res.questionList;
			});
		},

		// 获取商品的所有计划
		getPlain() {
			let send = {
				comboId: '',
				backInsureFlag: 1,
			};
			getInsuerInfo(this.qrCodeInfo.proId, send).then(res => {
				this.proInfo = res.product;
				this.proPlanList = res.combos;

				this.immediateExtendTime = res.product.immediateExtendTime;

				// 注意：领取保险，如果有多个计划，只选取第一个计划
			});
		},

		// 计划改变
		planChange(plan) {
			this.planId = plan.comboId;
		},

		// 保障期限改变
		insureTimeChange(info) {
			this.protectInfo = info;
		},

		submit() {
			if (this.qrCodeInfo.automaticPay) {
				// 有保险编码
				this.hasCodeGetInfo();
			} else {
				// 保险信息登记
				this.$refs.beautifulAssured.getResult().then(res => {
					this.getInsureInfo(res);
				});
			}
		},

		// 有保险编码的订单提交--获取被保人信息
		hasCodeGetInfo() {
			if (this.activeName === 'idCard') {
				// 身份证号码
				this.$refs.assuredIdCard.getFormValue().then(info => {
					this.hasCodeSubmit(info);
				});
			} else {
				// 护照
				this.$refs.assuredPassport.getFormValue().then(info => {
					// 要格式化性别的值
					info.sex = this.sexFormatter(info.sex);
					this.hasCodeSubmit(info);
				});
			}
		},

		sexFormatter(text) {
			if (text === '男' || text === 'male') {
				return '男';
			}

			if (text === '女' || text === 'female') {
				return '女';
			}
			return text;
		},

		// 有保险编码的订单提交--提交订单
		hasCodeSubmit(assured) {
			this.$refs.insureInfo.getResult().then(insureInfoData => {
				// 即时起保，从服务器获取时间戳,防止客户端时间不准
				if (insureInfoData.isNextSecondStart) {
					Toast.loading({
						duration: 0, // 持续展示 toast
						forbidClick: true,
						message: '提交信息中',
					});
					http_getServerTime().then(res => {
						Toast.clear();
						this.submitForm(insureInfoData, [assured], Number(res.timestamp)).then(order => {
							this.mixin_updateSignaturesBillId(order.id);
							// 提交成功
							this.hasCodeSendCode(order.id, assured.insuranceCode);
						});
					});
				} else {
					this.submitForm(insureInfoData, [assured]).then(order => {
						this.mixin_updateSignaturesBillId(order.id);
						// 提交成功
						this.hasCodeSendCode(order.id, assured.insuranceCode);
					});
				}
			});
		},

		hasCodeSendCode(billId, code) {
			// 查询订单状态
			Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: '提交信息中',
			});
			getBillState(billId).then(res => {
				if (res.billState === 1) {
					// 待核保
					setTimeout(() => {
						Toast.clear();
						this.hasCodeSendCode(billId, code);
					}, 1500);
				} else if (res.billState === 2) {
					// 待支付
					// 发送保险编码
					codePay(billId, code).then(() => {
						Toast.clear();
						this.showSuccessPage('提交成功');
					});
				} else {
					Toast.clear();
					Toast.fail(`错误！订单状态：${billId}`);
				}
			});
		},

		// 保险编码投保,tab切换
		cardTypeTabChange(val) {
			// 证件号码为“身份证”时，切换中文，为“护照”时，切换英文
			this.$i18n.locale = val === 'idCard' ? 'zhCN' : 'en';
		},

		// 获取投保信息
		getInsureInfo(assured) {
			this.$refs.insureInfo.getResult().then(insureInfoData => {
				// 即时起保，从服务器获取时间戳,防止客户端时间不准
				if (insureInfoData.isNextSecondStart) {
					Toast.loading({
						duration: 0, // 持续展示 toast
						forbidClick: true,
						message: '提交信息中',
					});
					http_getServerTime().then(res => {
						Toast.clear();
						this.submitForm(insureInfoData, assured, Number(res.timestamp)).then(order => {
							this.mixin_updateSignaturesBillId(order.id);
							// 提交成功
							this.showSuccessPage();
						});
					});
				} else {
					this.submitForm(insureInfoData, assured).then(order => {
						this.mixin_updateSignaturesBillId(order.id);
						// 提交成功
						this.showSuccessPage();
					});
				}
			});
		},

		// 提交订单 投保信息 被保险人 时间戳
		submitForm(insureInfo, assured, timeStamp = 0) {
			let assuredList = assured.map(item => {
				return {
					name: item.userName,
					certificateType: item.cardType,
					certificateContent: item.cardNum,
					birthday: this.$base.submitDateFormatter(item.birthday),
					sex: item.sex,
					mobile: item.phone,
				};
			});

			let send = {
				assuredList, // 被保人
				insurer: {
					insBirthday: this.$base.submitDateFormatter(this.qrCodeInfo.birthday),
					insCredentials: this.qrCodeInfo.cardNum,
					insCredentialsType: this.qrCodeInfo.cardType,
					insEmail: this.qrCodeInfo.email,
					insName: this.qrCodeInfo.insureName,
					insPhone: this.qrCodeInfo.mobile,
					insSex: this.qrCodeInfo.sex,
					insType: insureUserType.filter(item => item.value === this.qrCodeInfo.insureType)[0].name,
				},
				extentionList: [],
				insBill: {
					enableDate: `${this.$base.submitDateFormatter(insureInfo.start)} ${insureInfo.isNextSecondStart ? this.$base.getTime(timeStamp + this.immediateExtendTime * 1000 * 60) : insureInfo.startSecond}`,
					disEnableDate: this.$base.submitDateFormatter(insureInfo.end, 2), //终保日期
					id: '',
					insureType: '2', //出单方式
					proId: this.qrCodeInfo.proId, //产品ID
					remark: '',
					platform: 'LP',
					assuredSendMsg: 1,
					lpId: this.qrCodeInfo.id,
				},
				optType: '2',
				productPriceVo: {
					comboId: this.planId,
					proId: this.qrCodeInfo.proId,
					viewTime: this.protectInfo.view_time,
					eleView1: '',
					eleView2: '',
					priceId: this.protectInfo.price_id,
					sex: '',
					viewAgeBelong: '',
					insureTime: this.protectInfo.insure_time,
					insureTimeUnit: this.protectInfo.insure_time_unit,
				},
			};
			Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: '提交信息中',
			});
			return new Promise(resolve => {
				submitBill(send).then(
					res => {
						khs.finish(res.data.id);

						Toast.clear();
						resolve(res.data);
					},
					err => {
						console.log(err);
					},
				);
			});
		},

		// 打开成功页面
		showSuccessPage(text = '信息登记已完成') {
			this.showSuccessText = text;
			this.showSuccess = true;
		},
		//保障详情跳转
		handleGo(val) {
			if (val == 1) {
				this.$router.push({
					name: 'safeguardClause',
					params: {
						productId: this.proSimpleInfo.id,
						comboId: this.planId,
					},
				});
			} else {
				this.$router.push({
					name: 'planDetail',
					params: {
						productId: this.proSimpleInfo.id,
						comboId: this.planId,
					},
				});
			}
		},

		// 继续投保
		continueInsure() {
			window.location.href = window.localStorage.getItem('lpIndexUrl');
		},
	},
};
</script>

import { render, staticRenderFns } from "./assured.vue?vue&type=template&id=541355da&scoped=true&"
import script from "./assured.vue?vue&type=script&lang=js&"
export * from "./assured.vue?vue&type=script&lang=js&"
import style0 from "./assured.vue?vue&type=style&index=0&id=541355da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "541355da",
  null
  
)

export default component.exports